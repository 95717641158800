import React, { useState } from "react"

function Tabs(props) {
  const {
    tabs,
    heading
  } = props

  const [toggle, setToggle] = useState({
    frontend: true,
    backend: false,
    database: false,
    cms: false,
    ecommerce: false,
    cloud: false,
  })

  const handleToggle = e => {
    const name = e.target.getAttribute('name')

    setToggle({
      [name]: !toggle[name]
    })
  }

  return (
    <>
      <div className="section-tech">
        <div className="container">
          <h2 className="title text-center">{heading}</h2>
          <div className="tab-wrapper">
            <ul className="nav unstyled">
              {tabs.map((item, i) => {
                return (
                  <li key={i} className={toggle[item.title.toLowerCase()] ? 'nav-list p-lg active': 'nav-list p-lg'}>
                    <button className={toggle[item.title.toLowerCase()] ? 'nav-link active' : 'navlink'}
                      id={item.identity}
                      data-toggle="tab"
                      role="tab"
                      aria-controls={item.link}
                      aria-selected="true"
                      onClick={handleToggle}
                      name={item.title.toLowerCase()}>{item.title}</button>
                  </li>
                )
              })}
            </ul>
            <div className="tab-content" id="myTabContent">
                {tabs.map((item, i) => {
                  return (
                    <div key={i} className={toggle[item.title.toLowerCase()] ? 'tab-pane fade show active' : 'tab-pane fade'} id={item.link} role="tabpanel"  aria-labelledby={item.identity}>
                      <div className="content-holder d-flex">
                        {item.content.map((logo, i) => {
                          return (
                            <div key={i} className="image-holder">
                              {toggle[item.title.toLowerCase()] &&
                                <img src={logo} className="img-fluid" alt="Logo" />
                              }
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tabs
