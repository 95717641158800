import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Brands from "../components/Brands"
import SampleWorksSwiper from "../components/SampleWorksSwiper"
import Testimonials from "../components/Testimonials";
import Services from '../components/Services'
import Tab from "../components/Tabs"
import Plans from "../components/Plan"
import ContentImage from "../components/ContentImage"

const logoFeat = [
  {
    name: 'Accenture',
    image: require('../assets/images/brands-accenture.png'),
    link: 'https://www.accenture.com/'
  }, {
    name: 'Plotly',
    image: require('../assets/svg/brands-plotly.svg'),
    link: 'https://plotly.com/'
  }, {
    name: 'King Kong',
    image: require('../assets/images/brands-kingkong.png'),
    link: 'https://kingkong.com.au/'
  }, {
    name: 'CraftersCompanion',
    image: require('../assets/images/brands-crafters-companion.png'),
    link: 'https://www.crafterscompanion.com/'
  }, {
    name: 'Holcim',
    image: require('../assets/svg/brands-holcim.svg'),
    link: 'https://www.holcim.com/'
  }
]

const testimonyList = [
  {
    text: "Wow. U guys are simply amazing. This design is stunnning. Your design team rocks @Peng! They nailed it first go. Quite impressive",
    image: require('../assets/images/eddie-machaalani.png'),
    name: 'Eddie Machaalani',
    position: 'BigCommerce, Co-founder'
  },
  {
    text: `I was incredibly impressed by how you matched specs with clean code. And the team is super easy to work with!`,
    image: require('../assets/images/mohammed-abid.png'),
    name: 'Mohammed Abid',
    position: 'Facebook, Product Designer'
  }, {
    text: `Wanted to say I love my 2 sites. They are great. The site gets a ton of conversions. I'd say at least half my phone calls are form the website call now buttons.`,
    image: require('../assets/images/troy-parsons.png'),
    name: 'Troy Parsons',
    position: 'AAR Auto Glass, Owner'
  }
]

const worksSwiperContent = [
  {
    comment: "You (Peng) and your team have provided exceptional service and value to Plotly for product development and our website for a long time.  All of you are well respected for your work and commitment.",
    long: true,
    slideItem: 'slideZero',
    avatar: require('../assets/images/jim_mcintosh.png'),
    name: "Jim McIntosh",
    position: "Executive Chair, Plotly",
    projectImage: require('../assets/images/plotly.png'),
    link: 'https://plotly.com/',
    external: true
  },
  {
    comment: "The folks at PSD Wizard and FullStack HQ are great to work with. We needed a quick turnaround for a website and they pulled off a pixel perfect execution and quickly responded to feedback & page design updates. And despite being on the other side of the world, are responsive communicators and very organized.  They're also very knowledgable and provided detailed information and recommendations for website integrations and deployment throughout the development process. Highly recommended!",
    long: true,
    slideItem: 'slideOne',
    avatar: require('../assets/images/frank-campanella.png'),
    name: "Frank Campanella",
    position: "Narrative I/O",
    projectImage: require('../assets/images/sample-projects-1.png'),
    link: 'https://www.narrative.io/',
    external: true
  },
  {
    comment: "The Fullstack team stepped up to the challenge of re-developing our complex marketing website, with dozens of pages and features, in just a few weeks. They acted as an extension to our team throughout the process, were open and communicative and pulled out all the stops to deliver the website on time.",
    long: true,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/morgan-howson.png'),
    name: "Morgan Howson",
    position: "Head of Product, Billsby",
    projectImage: require('../assets/images/sample-billsby.png'),
    link: 'https://www.billsby.com/',
    external: true
  },
  {
    comment: "So far we’ve been having a wonderful experience working with Fullstack for both EventBuilder and Jugs Sports. Your communication is succinct and things are getting done. We’re excited to start building the Copious internal website, as well as partner with you again for future projects",
    long: true,
    slideItem: 'slideThree',
    avatar: require('../assets/images/jeff-bronson.png'),
    name: "Jeff Bronson",
    position: "Director of Digital Strategy, Copious",
    projectImage: require('../assets/images/sample-projects-2.png'),
    link: 'https://upliftcx.com/',
    external: true
  },
  {
    comment: "First of all, I just want to say a big thank you for the quality and efficiency of your team's work. Pamela has been really amazing and prompt with all projects and tasks!",
    long: false,
    slideItem: 'slideThree',
    avatar: require('../assets/images/nish-menon.png'),
    name: "Nish Menon",
    position: "Founder, My Muscle Chef",
    projectImage: require('../assets/images/sample-projects-3.png'),
    link: 'https://www.mymusclechef.com/',
    external: true
  },
  {
    comment: "Wow @peng & @pamela - this is really good stuff! You worked really fast and these pages look great. Very good work guys! Keep it coming",
    long: false,
    slideItem: 'slideFour',
    avatar: require('../assets/images/kevin-kaninng.png'),
    name: "Kevin Canning",
    position: "Founder, Pearls of Joy",
    projectImage: require('../assets/images/sample-projects-11.png'),
    link: 'https://www.pearlsofjoy.com/',
    external: true
  },
  {
    comment: "Thank YOU Pamela - the site looks AMAZING so far! I know we're all working feverishly to get last minute touches and fix bugs, but wow I am pleased!",
    long: false,
    slideItem: 'slideFive',
    avatar: require('../assets/images/ashley-mcnamara.png'),
    name: "Ashley McNamara",
    position: "CEO, Pure Pearls",
    projectImage: require('../assets/images/sample-projects-9.png'),
    link: 'https://www.purepearls.com/',
    external: true
  },
  {
    comment: "Great Mary, the site is looking awesome!!! I’m stoked!",
    long: false,
    slideItem: 'slideSix',
    avatar: require('../assets/images/adam-phillips.png'),
    name: "Adam Phillips",
    position: "Founder, LifeGrip",
    projectImage: require('../assets/images/sample-projects-15.png'),
    link: 'https://lifegrip.com.au/',
    external: true
  },
   {
    comment: "The client (and me) are thrilled with the end result and are thankful for you and your team's great skills, communication, timeless, and quality of work!",
    long: false,
    slideItem: 'slideSeven',
    avatar: require('../assets/images/tim-haskins.png'),
    name: "Tim Haskins",
    position: "CEO, UpliftCX",
    projectImage: require('../assets/images/sample-projects-18.png'),
    link: 'https://www.segra.com/',
    external: true
  },
  {
    comment: "First of all, I just want to say a big thank you for the quality and efficiency of your team’s work. Pamela has been really amazing and prompt with all projects and tasks!",
    long: true,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/renata-reis.png'),
    name: "Renata Reis",
    position: "Marketing Manager, collective.care",
    projectImage: require('../assets/images/sample-projects-4.png'),
    link: 'https://www.taylorclinic.com.au/',
    external: true
  },
  {
    comment: "Thanks for all your sterling work on the platform - its looking really good.",
    long: false,
    slideItem: 'slideEight',
    avatar: require('../assets/images/elizabeth-fowkes.png'),
    name: "Elizabeth Fowkes",
    position: "Marketing Executive, CyberTalks",
    projectImage: require('../assets/images/sample-projects-6.png'),
    link: 'https://cybertalks.co.uk/',
    external: true
  },
  {
    comment: "I would like to sincerely thank you for the excellent work you have done in developing and maintaining the G-24 website. I appreciate all the help from your team in the past two years. Thank you for your support.",
    long: true,
    slideItem: 'slideNine',
    avatar: require('../assets/images/shichao-zhou.png'),
    name: "Shichao Zhou",
    position: "G-24 Secretariat, Research Officer",
    projectImage: require('../assets/images/sample-projects-5.png'),
    link: 'https://www.g24.org/',
    external: true
  }
]

const servicesInfo = [
  {
    image: require('../assets/images/services-img1.png'),
    desc: 'Scale your development capacity without wasting time on finding reliable team'
  }, {
    image: require('../assets/images/services-img2.png'),
    desc: 'Our developers take ownership of their work on every project they join'
  }, {
    image: require('../assets/images/services-img3.png'),
    desc: "We fund every developer's learning, growth and well-being to stay productive"
  }, {
    image: require('../assets/images/services-img4.png'),
    desc: 'Cut down recruiting costs and operational overhead and headache'
  }, {
    image: require('../assets/images/services-img5.png'),
    desc: 'Proactive communication, consistently delivering detailed progress updates'
  }, {
    image: require('../assets/images/services-img6.png'),
    desc: 'Your secret weapon, extended development team'
  }
]

const plansInfo = [
  {
    image: require('../assets/images/plans-img-3.png'),
    title: 'Professional',
    price: '1800',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: '140-hour credit balance',
        description:
        <p>
         You will be given 140-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Book a call',
    variant: 'normal',
    buttonLink: 'https://calendly.com/fullstackhq/meeting',
    buttonBeacon: false,
    buttonExternal: true
  },
  {
    image: require('../assets/images/plans-img-2.png'),
    title: 'Starter',
    price: '1200',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: '80-hour credit balance',
        description:
        <p>
         You will be given 80-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Book a call',
    variant: 'outline',
    buttonLink: 'https://calendly.com/fullstackhq/meeting',
    buttonBeacon: false,
    buttonExternal: true
  },
  {
    image: require('../assets/images/plans-img-1.png'),
    title: 'Basic',
    price: '720',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: '40-hour credit balance',
        description:
        <p>
         You will be given 40-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Book a call',
    variant: 'outline',
    buttonLink: 'https://calendly.com/fullstackhq/meeting',
    buttonBeacon: false,
    buttonExternal: true
  }
]

const tabsInfo = [
  {
    title: 'Frontend',
    identity: 'tab1-tab',
    link: 'development#!tab1',
    content: [
      require('../assets/svg/bootstrap.svg'),
      require('../assets/images/tailwind.png'),
      require('../assets/images/nextjs.png'),
      require('../assets/images/vuejs.png'),
      require('../assets/svg/typescript-logo.svg'),
      require('../assets/svg/react.svg'),
      require('../assets/svg/gatsby.svg'),
      require('../assets/svg/reactnative-logo.svg')
    ]
  },
  {
    title: 'Backend',
    identity: 'tab2-tab',
    link: 'development#!tab2',
    content: [
      require('../assets/svg/nodejs-logo.svg'),
      require('../assets/svg/express-logo.svg'),
      require('../assets/svg/firebase-logo.svg'),
      require('../assets/svg/laravel.svg'),
      require('../assets/svg/codeigniter.svg'),
      require('../assets/svg/slim.svg')
    ]
  },
  {
    title: 'CMS',
    identity: 'tab4-tab',
    link: 'development#!tab4',
    content: [
      require('../assets/svg/wordpress.svg'),
      require('../assets/svg/webflow.png'),
      require('../assets/images/builderio.png'),
      require('../assets/images/framer.png'),
      require('../assets/svg/contentful-logo.svg'),
      require('../assets/svg/prismic-logo.svg'),
      require('../assets/svg/strapi-logo.svg'),
      require('../assets/svg/hubspot.svg'),
      require('../assets/svg/squarespace.svg'),
      require('../assets/svg/wix.svg')
    ]
  },
  {
    title: 'E-Commerce',
    identity: 'tab5-tab',
    link: 'development#!tab5',
    content: [
      require('../assets/svg/shopify.svg'),
      require('../assets/svg/bigcommerce.svg'),
      require('../assets/svg/woocommerce.svg')
    ]
  },
  {
    title: 'Cloud',
    identity: 'tab6-tab',
    link: 'development#!tab6',
    content: [
      require('../assets/svg/aws.svg'),
      require('../assets/svg/googlecloud.svg'),
      require('../assets/svg/digitalocean.svg'),
      require('../assets/svg/mailchimp.svg'),
      require('../assets/svg/stripe.svg'),
      require('../assets/svg/paypal.svg')
    ]
  },
]

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: false,
  isFullContent: true,
  styleA: true,
  title: "We've helped some of the world-class entrepreneurs & tech companies increase efficiency & reduce development costs.",
  image: require('../assets/images/banner-dev.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function Development() {
  return (
    <>
      <OgTags
        ogTitle="Full Stack Dev Team for Your Small Business | Fullstack HQ"
        ogDescription="Fullstack HQ helped some of the world-class entrepreneurs & tech companies increase efficiency & reduce development costs."
        ogUrl="https://fullstackhq.com/development"
      />
      <Header />
      <main className="development initialized-header">
        <ContentImage
          content={cta}
        />
        <Testimonials
          title="Loved by agencies, startups and lean entrepreneurs"
          testimony={testimonyList}
        />
       <Brands
          text="Some of the brands we work with..."
          brands={logoFeat}
        />
        <Services
          heading="Our partner benefits"
          services={servicesInfo}
        />
        <Tab
          heading="Our supported tech stacks"
          tabs={tabsInfo}
        />
        <SampleWorksSwiper
          heading="Selected sample works"
          worksSwiper ={worksSwiperContent}
        />
        <Plans
          content={plansInfo}
          heading="No upfront cost, 7-day trial work"
        />
      </main>
      <Footer />
    </>
  )
}

export default Development
